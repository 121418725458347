@import "libs/presentation/src/lib/components/common/cssModules/setting.module";
@import "libs/presentation/src/lib/components/common/cssModules/mixin.module";

html,
body,
#__next,
#__next > main {
  height: 100%;

  * {
    word-break: break-all;
    overflow-wrap: anywhere;
  }
}

// チャネルトーク窓スタイル上書き
#ch-plugin-core {
  div[data-ch-testid="launcher"] {
    right: 3vw !important;
    bottom: 4.7vh !important;
    filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.7));
    transition: $base-transition;

    &:hover {
      opacity: 0.6;
    }
  }
}

.section {
  margin-bottom: 30px;

  &Container {
    width: 100%;
    border-radius: 8px;
    background-color: #fff;
    padding: 24px 30px;

    &:not(:last-child) {
      margin-bottom: 40px;
    }

    &HeadLine {
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      line-height: 1em;
      margin-bottom: 22px;

      &TitleWrapper {
        &Icon, &Title {
          vertical-align: top;
        }

        &Icon {
          margin-right: 9px;
        }

        &Title {
          margin-right: 17px;
          font-weight: bold;
          font-size: 18px;
        }

        &Note {
          font-weight: normal;
          font-size: 13px;
        }
      }
    }
  }
}

article {
  &:not(:last-child) {
    margin-bottom: 50px;
  }
}


.form,
.confirmation {
  &Group {
    margin-bottom: 40px;

    &Label {
      margin-bottom: 16px;
      font-weight: bold;
    }
  }

  &Fields {
    margin-bottom: 26px;

    &Item {
      display: flex;

      &Sentence {
        padding-top: 12px;
      }

      &Label {
        flex-shrink: 0;
        width: 154px;
        font-weight: bold;
        font-size: 16px;
        font-feature-settings: "palt";
      }

      &Value {
        & input, & select {
          height: 44px;
          padding-top: 0;
          padding-bottom: 0;
          background-color: #fff;
          border-radius: 8px;
        }
      }
    }

    @include mqMax($break-m) {
      &Item {
        display: block;

        &Label {
          padding-top: 0;
          margin-bottom: 10px;
        }

        &Value {
          display: block;
        }
      }
    }
  }
}

.form {
  &Fields {
    &Item {
      &Label {
        padding-top: 12px;
      }

      &Value {
        margin-bottom: 8px;
        width: 100%;
      }
    }
  }
}

.confirmation {
  &Fields {
    &Item {
      justify-content: space-between;
      padding: 8px 0;
      border-bottom: 1px solid #ddd;
    }
  }
}
